import { AzureCommunicationTokenCredential, CommunicationUserIdentifier } from '@azure/communication-common';
import {  
  CallComposite, 
  fromFlatCommunicationIdentifier, 
  useAzureCommunicationCallAdapter 
} from '@azure/communication-react';
import React, { useState, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth/core/Auth';
import cn from 'classnames';

const TeamsComponent = () => { 

  const { auth } = useAuth();
  const displayName = auth?.first_name ?? 'Guest'; 
  const [userId, setUserId] = useState('');
  const [token, setToken] = useState('');
  const [teamsMeetingLink, setTeamsMeetingLink] = useState('');
  const [message, setMessage] = useState('');
  const router = useNavigate(); 

  const credential = useMemo(() => {
    if (token) {
      return new AzureCommunicationTokenCredential(token)
    } 
    return;
    }, [token]);

  const callAdapterArgs = useMemo(() => {
    if (userId && credential && displayName && teamsMeetingLink) {
      return {
        userId: fromFlatCommunicationIdentifier(userId),
        displayName,
        credential,
        locator: { meetingLink: teamsMeetingLink },
      }
    }
    return {};
  }, [userId, credential, displayName, teamsMeetingLink]);

  const callAdapter = useAzureCommunicationCallAdapter(callAdapterArgs);
  const location = useLocation(); 

  const {state} = useLocation(); 

  // console.log('jsgdfsjd', state?.useId, auth?.user_id)

  useEffect(() => { 
    
    if(state?.useId != auth?.user_id) {
      return router('/')
    }

    requestPermissions();
    const queryParams = new URLSearchParams(location.search);
    // const myParam = queryParams.get('url');
    const myParam = state?.url ?? queryParams.get('url');
    setTeamsMeetingLink(myParam);

    const init = async () => {
      setMessage('Getting ACS user');
      //Call Azure Function to get the ACS user identity and token
      const res = await fetch(process.env.REACT_APP_ACS_USER_FUNCTION);
      const user = await res.json();
      setUserId(user.userId);
      setToken(user.token);
      // setMessage('Getting Teams meeting link...');
      // //Call Azure Function to get the meeting link
      // const resTeams = await fetch(process.env.REACT_APP_TEAMS_MEETING_FUNCTION);
      // const link = await resTeams.text();
      // setTeamsMeetingLink(link);
      // setMessage('');
      // console.log('Teams meeting link', link);
    }
    init();

  }, []);

  const requestPermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      });
    } catch (err) {
      console.error('Permission denied', err);
    }
  };

  if (callAdapter) {
    return (
      <div className='vh-100'>
        {/* <h1>Contact Customer Service</h1> */}
        <div className="teams_wrapper">
            <CallComposite
              adapter={callAdapter}
            />
        </div>
      </div>
    );
  }

  if (!credential) {
    return  <div className='pageLoading'>
                <span
                  className={cn(
                    'd-flex h-100vh w-100 flex-column align-items-center justify-content-center'
                  )}
                >
                  <span className={"loading"} />
                </span>
            </div>
    // return <div className='vh-100'>Failed to construct credential. Provided token is malformed.</div>;
  }

  if (message) {
    return <div className='vh-100'>{message}</div>;
  }
  
  return <div className='vh-100'>Initializing...</div>;
};

export default TeamsComponent;